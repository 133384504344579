import "./App.css";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Navbar from "./components/ui/Navbar";
import Footer from "./components/ui/Footer";
import Home from "./Pages/HomePage";
import styled from "styled-components";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Wrapper>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </Wrapper>
        <Footer />
      </Router>
    </div>
  );
}

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  `
export default App;
