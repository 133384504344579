import React from 'react';
import styled from "styled-components"
<style>
  @import url('https://fonts.googleapis.com/css2?family=Anton&family=Bebas+Neue&family=Dancing+Script:wght@500;700&family=Montserrat:wght@200;300;400&family=Poppins&family=Questrial&family=Quicksand:wght@300&family=Raleway:wght@100&family=Roboto:wght@300&family=Space+Grotesk&family=Suez+One&display=swap');
</style>

function BuiltForCreatives() {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image src="./img/contact.svg" />
      </ImageWrapper>

      <TextWrapper>
        <Header>Built for Creatives, by Creatives</Header>
        <Text>
          Crafted with the creative professional in mind, our platform offers
          the flexibility and power you need to bring your ideas to life.
          Whether you're developing AI models or designing digital experiences,
          we provide the tools to make it happen.
        </Text>

        <IconsWrapper>
          <IconTextWrapper>
            <Icon src="./img/all-in-one.svg" />
            <IconText>Scalable AI Infrastructure</IconText>
          </IconTextWrapper>

          <IconTextWrapper>
            <Icon src="./img/file-sharing.svg" />
            <IconText>Enterprise-Grade Security</IconText>
          </IconTextWrapper>

          <IconTextWrapper>
            <Icon src="./img/design-freedom.svg" />
            <IconText>Total Design Freedom</IconText>
          </IconTextWrapper>
        </IconsWrapper>
      </TextWrapper>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 130px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

export const TextWrapper = styled.div`
  @media screen and (max-width: 1024px) {
    margin-left: auto;
    margin-right: auto;
  }
`

export const Header = styled.p`
  color: #000000;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 38px;
  width: 387px;

  @media screen and (max-width: 768px) {
    font-size: 29px;
    width: 280px;
  }
`

export const Text = styled.p`
  color: #000000;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 16px;
  width: 402px;
  line-height: 30px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    width: 280px;
  }
`

export const IconsWrapper = styled.div`
  display: flex;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const IconTextWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 180px;

  @media screen and (max-width: 768px) {
    width: 300px;
  }
`

export const Icon = styled.img`
  width: 32px;
  height: 32px;

  @media screen and (max-width: 768px) {
    padding-right: 30px;
  }
`

export const IconText = styled.p`
  color: #000000;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  width: 95px;

  @media screen and (max-width: 768px) {
    width: 400px;
  }
`

export const ImageWrapper = styled.div`
  @media screen and (max-width: 1130px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`

export const Image = styled.img`
  width: 563px;
  height: 482px;

  @media screen and (max-width: 1130px) {
    width: 95%;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`

export default BuiltForCreatives;