import React from 'react';
import styled from "styled-components"
import SectionWrapper from '../ui/section-wrapper';
<style>
  @import url('https://fonts.googleapis.com/css2?family=Anton&family=Bebas+Neue&family=Dancing+Script:wght@500;700&family=Montserrat:wght@200;300;400&family=Poppins&family=Questrial&family=Quicksand:wght@300&family=Raleway:wght@100&family=Roboto:wght@300&family=Space+Grotesk&family=Suez+One&display=swap');
</style>

function WhatWeOffer() {
  return (
    <SectionWrapper id="services">
      <WhatWeOfferWrapper>
        <TextWrapper>
          <Header>What We Offer</Header>
          <Text>
            At Dreamverse, we empower enterprises and research institutions with
            cutting-edge AI solutions and development support. Our offerings are
            designed to scale with your needs, enabling you to implement secure
            custody solutions and drive innovation.
          </Text>
        </TextWrapper>

        <CardsWrapper>
          <CardWrapper>
            <Image src="./img/card-1.png" />
            <CardHeader>Workflows That Work</CardHeader>
            <CardText>
              Streamline your operations with intelligent workflows that
              optimize efficiency and collaboration. Our solutions are tailored
              to fit seamlessly into your existing processes, allowing your team
              to work smarter, not harder.
            </CardText>
          </CardWrapper>

          <CardWrapper>
            <Image src="./img/card-2.png" />
            <CardHeader>All-In-One Solution</CardHeader>
            <CardText>
              Discover an integrated platform that brings all the tools you need
              into one place. From AI development to secure file sharing, our
              solution simplifies complex tasks and enhances productivity.
            </CardText>
          </CardWrapper>

          <CardWrapper>
            <Image src="./img/card-3.png" />
            <CardHeader>Comprehensive Customer Support</CardHeader>
            <CardText>
              Our dedicated support team is here to assist you every step of the
              way. Whether you need technical guidance or strategic advice, we
              provide the expertise to help you achieve your goals.
            </CardText>
          </CardWrapper>

          <CardWrapper>
            <Image src="./img/card-4.png" />
            <CardHeader>Smart Automation Tools</CardHeader>
            <CardText>
              Automate repetitive tasks and improve accuracy with our smart
              automation tools. Designed to reduce manual workload, these tools
              allow your team to focus on innovation and strategic initiatives.
            </CardText>
          </CardWrapper>
        </CardsWrapper>
      </WhatWeOfferWrapper>
    </SectionWrapper>
  );
}

export const TextWrapper = styled.div`
text-align: center;

  @media screen and (max-width: 1050px) {
    margin-left: 0px;
  }

`

export const WhatWeOfferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 768px) {
   gap : 1rem;
  }
  `
export const Header = styled.p`
  color: #000000;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 38px;

  @media screen and (max-width: 1050px) {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }


  @media screen and (max-width: 500px) {
    font-size: 24px;
  }
`

export const Text = styled.p`
  color: #000000;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 16px;
  width: 492px;
  line-height: 25px;

  @media screen and (max-width: 1050px) {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 768px) {
    width: 75%;
    font-size: 14px;
  }
`

export const CardsWrapper = styled.div`
  display: flex;
  width: 980px;
  margin-top: 60px;

  @media screen and (max-width: 1050px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
  }

  @media screen and (max-width: 768px) {
    margin-top: 40px;
    gap: 1rem;
  }
`

export const CardWrapper = styled.div`
  width: 100%;

  @media screen and (max-width: 1050px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 40px;
  }
`

export const Image = styled.img`
  border-radius: 10px;
  border: 2px solid #000000;
`

export const CardHeader = styled.p`
  margin : 0 auto;
  color: #000000;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 22px;
  width: 80%;
  text-align:  center;
  margin-bottom: 10px;

  @media screen and (max-width: 1050px) {
    width: 100%;
    text-align: center;
  }
`

export const CardText = styled.p`
  text-align: center;
  margin: 0 auto;

  color: #000000;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  line-height: 25px;
  width: 80%;

  @media screen and (max-width: 1050px) {
    width: 70%;
    text-align: center;
  }
`;

export default WhatWeOffer;