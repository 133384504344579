import React from "react";
import styled from "styled-components";
import Button from '../elements/Button';
import sendEmail from '../../utils/send-email';

const FooterContainer = styled.footer`
  background-color: #f0f0f0;
  padding: 40px 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const FooterSection = styled.div`
  flex: 1;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 10px;
      color: #666;

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
`;



const SubscribeButton = styled.button`
  background-color: #5a20cb;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #4a18a7;
  }
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 8rem;
  width: 100%;
`;

const FooterLogo = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 10rem;
    height: auto;
    margin-right: 10px;
  }
`;

const FooterTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 8rem;
  margin-bottom: 2rem;
  gap: 2rem;
  width: 100%;
`;

const FooterCopyright = styled.div`
  p {
    color: #666;
    font-size: 14px;
    margin: 0;
  }
`;

const SocialIcons = styled.div`
  a {
    display: inline-block;
    margin-right: 15px;
    color: #333;

    i {
      font-size: 18px;
    }
  }
`;

const LanguageSelect = styled.select`
  background-color: var(--primary-color);
  color: white;
  padding: .3rem 1.2rem;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterTop>
        <FooterLogo>
          <img src="./img/logo.svg" alt="Dreamverse Holdings Logo" />
        </FooterLogo>
        <ContactSection>
          <h3 style={{textAlign: "center"}}>
            Let's bring your vision to life.
            <br /> Connect with us to kickstart your project today.
          </h3>
          <Button variant="primary" onClick={() => sendEmail()}>
            Get In Touch
          </Button>
        </ContactSection>
      </FooterTop>
      <FooterContent>
        <FooterSection>
          <h3>Product</h3>
          <ul>
            <li>
              <a href="#">Features</a>
            </li>
            <li>
              <a href="#">Pricing</a>
            </li>
          </ul>
        </FooterSection>
        <FooterSection>
          <h3>Resources</h3>
          <ul>
            <li>
              <a href="#">Blog</a>
            </li>
            <li>
              <a href="#">User guides</a>
            </li>
            <li>
              <a href="#">Webinars</a>
            </li>
          </ul>
        </FooterSection>
        <FooterSection>
          <h3>Company</h3>
          <ul>
            <li>
              <a href="#">About us</a>
            </li>
            <li>
              <a href="#">Contact us</a>
            </li>
          </ul>
        </FooterSection>
        {/* <FooterSection>
          <h3>Plans & Pricing</h3>
          <ul>
            <li>
              <a href="#">Personal</a>
            </li>
            <li>
              <a href="#">Start up</a>
            </li>
            <li>
              <a href="#">Organization</a>
            </li>
          </ul>
        </FooterSection> */}
      </FooterContent>
      <FooterBottom>
        <LanguageSelect>
          <option value="en">English</option>
        </LanguageSelect>
        <FooterCopyright>
          <p>
            &copy; {new Date(Date.now()).getFullYear()} Dreamverse Holdings,
            Inc. Privacy - Terms - Sitemap
          </p>
        </FooterCopyright>
        <SocialIcons>
          <a href="#">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="#">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#">
            <i className="fab fa-linkedin-in"></i>
          </a>
          <a href="#">
            <i className="fab fa-youtube"></i>
          </a>
        </SocialIcons>
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;
