import React , {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom'
import Button from '../elements/Button';
import SectionWrapper from '../ui/section-wrapper';
import styled from 'styled-components';
import sendEmail from '../../utils/send-email';

<style>
  @import url('https://fonts.googleapis.com/css2?family=Anton&family=Bebas+Neue&family=Dancing+Script:wght@500;700&family=Montserrat:wght@200;300;400&family=Poppins&family=Questrial&family=Quicksand:wght@300&family=Raleway:wght@100&family=Roboto:wght@300&family=Space+Grotesk&family=Suez+One&display=swap');
</style>

function Communicate() {

  const history = useNavigate()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  return (
    <SectionWrapper
      backgroundImage={'url("./img/bg.png")'}
      id="home"
      backgroundRepeat="no-repeat"
      backgroundPosition={`${screenWidth < 1024 ? "top" : "center"}`}
    >
      <TextWrapper>
        <Header>Inspire. Innovate. Achieve.</Header>
        <Text>
          At Dreamverse, we empower your ideas with the tools to thrive.
        </Text>
        <Button variant="primary" onClick={() => sendEmail()}>
          Get In Touch
        </Button>

        <IconsWrapper>
          <IconTextWrapper>
            <Icon src="./img/speed.svg" />
            <IconText>Speed & Security</IconText>
          </IconTextWrapper>

          <IconTextWrapper>
            <Icon src="./img/flexbility.svg" />
            <IconText>Flexibility & Scalability</IconText>
          </IconTextWrapper>

          <IconTextWrapper>
            <Icon src="./img/collaboration.svg" />
            <IconText>Better Collaboration</IconText>
          </IconTextWrapper>
        </IconsWrapper>
      </TextWrapper>

      <ImageWrapper>
        <Image src="./img/communicate.svg" />
      </ImageWrapper>
    </SectionWrapper>
  );
}



export const TextWrapper = styled.div`
  @media screen and (max-width: 1024px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 768px) {
    text-align: center;
  }

`

export const Header = styled.p`
  color: #000000;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 48px;
  width: 478px;
  line-height: 60px;

  @media screen and (max-width: 768px) {
    font-size: 29px;
    width: 280px;
    line-height: 40px;
    margin-bottom: 1rem;
  }
`

export const Text = styled.p`
  color: #000000;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 20px;
  width: 380px;
  line-height: 30px;
  margin-bottom : 2rem;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    width: 280px;
  }
`

export const IconsWrapper = styled.div`
  display: flex;
  margin-top: 60px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 40px;
    align-items: center;

  }
`

export const IconTextWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 150px;

  @media screen and (max-width: 768px) {
    width: 200px;
  }
`

export const Icon = styled.img`
  fill: var(--primary-color);
  color: var(--primary-color);
  width: 42px;
  height: 42px;

  @media screen and (max-width: 768px) {
    width: 38px;
    height: 38px;
    padding: 5px;
  }
`;

export const IconText = styled.p`
  color: #000000;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  width: 90px;

  @media screen and (max-width: 768px) {
   flex : 1;
    text-align: left;
    margin-left: .7rem;

  }
`

export const ImageWrapper = styled.div`
  min-width: 20rem;
`

export const Image = styled.img`
  width: 563px;
  height: 482px;

  @media screen and (max-width: 1130px) {
    width: 100%;
  }
`

export default Communicate;