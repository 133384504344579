import React from 'react';
import styled from "styled-components"
import Communicate from '../components/HomePage/Communicate'
import RightSoftware from '../components/HomePage/RightSoftware'
import WhatWeOffer from '../components/HomePage/WhatWeOffer'
import BuiltForCreatives from '../components/HomePage/BuiltForCreatives'
import IndustryLeaders from '../components/HomePage/IndustryLeaders'
import ClientsReview from '../components/HomePage/ClientsReview'
import PricingOptions from '../components/HomePage/PricingOptions'
import MaximizeYourProductivity from '../components/HomePage/MaximizeYourProductivity'
function HomePage() {
    console.log("Home component rendered");
  return (
    <Wrapper>
      <Communicate />
      <RightSoftware />
      <WhatWeOffer />
      <BuiltForCreatives />
      <IndustryLeaders />
      <ClientsReview />
      {/* <PricingOptions /> */}
      <MaximizeYourProductivity />
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  width: 100%;

`

export default HomePage;