import React from 'react';
import styled from "styled-components"
import SectionWrapper from '../ui/section-wrapper';
<style>
  @import url('https://fonts.googleapis.com/css2?family=Anton&family=Bebas+Neue&family=Dancing+Script:wght@500;700&family=Montserrat:wght@200;300;400&family=Poppins&family=Questrial&family=Quicksand:wght@300&family=Raleway:wght@100&family=Roboto:wght@300&family=Space+Grotesk&family=Suez+One&display=swap');
</style>

function RightSoftware() {
  return (
    <SectionWrapper color="var(--primary-color)" id="about">
      <TextWrapper>
        <Header>With the Right Software, Great Things Can Happen</Header>
        <Text>
          Unlock your team’s potential with cutting-edge solutions tailored to
          drive innovation and success. Whether you're streamlining processes,
          enhancing collaboration, or bringing new ideas to life, we provide the
          platform to make it happen. Explore our features and see how we can
          help you achieve your goals.
        </Text>
      </TextWrapper>
    </SectionWrapper>
  );
}


export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 950px) {
    flex-direction: column;
    padding-top: 70px;
    padding-bottom: 70px;
    align-items: center;
    text-align: center;
    gap: 1rem;
  }
`

export const Header = styled.p`
  color: white;
  font-size: 42px;
  width: 375px;
  line-height: 50px;

  @media screen and (max-width: 950px) {
    text-align: left;
    margin-left: -110px;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    margin: 0;
  }

  @media screen and (max-width: 570px) {
    margin: 0;
    font-size: 24px;
    line-height: 35px;
  }
`;

export const Text = styled.p`
  color: #fff;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  width: 481px;
  line-height: 30px;

  @media screen and (max-width: 950px) {
    text-align: left;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
  }

  @media screen and (max-width: 570px) {
    font-size: 15px;
    width: 280px;
    /* margin-left: -70px; */
  }
`

export default RightSoftware;