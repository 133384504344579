import React from 'react';
import styled from "styled-components"
import SectionWrapper from '../ui/section-wrapper';
<style>
  @import url('https://fonts.googleapis.com/css2?family=Anton&family=Bebas+Neue&family=Dancing+Script:wght@500;700&family=Montserrat:wght@200;300;400&family=Poppins&family=Questrial&family=Quicksand:wght@300&family=Raleway:wght@100&family=Roboto:wght@300&family=Space+Grotesk&family=Suez+One&display=swap');
</style>

function  IndustryLeaders() {
  return (
    <SectionWrapper
      color="#ff5600"
      style={{
        marginTop: "4rem",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      id="projects"
    >
      <TextWrapper>
        <Header>Trusted Among Industry Leaders</Header>
        <Text>
          Our solutions are trusted by industry pioneers to drive innovation,
          streamline processes, and unlock new possibilities. Partner with us to
          stay ahead in a rapidly evolving landscape.
        </Text>
      </TextWrapper>

      <LogosWrapper>
        <FirstLogoRow>
          <Logo src="./img/penyesa.svg" />
          <Logo src="./img/overdub_logo.png" />
          <Logo src="./img/uj_peets.svg" />
        </FirstLogoRow>
      </LogosWrapper>
    </SectionWrapper>
  );
}

export const TextWrapper = styled.div`
  text-align: center;
  margin-bottom: 70px;

  width: 100%;

  * > {
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    margin-left: 0px;
  }
`

export const Header = styled.p`
  color: #000000;
  font-size: 38px;
  font-family: 'Space Grotesk', sans-serif;

  @media screen and (max-width: 1024px) {
    text-align: center;
    width: 100%;
  }

  @media screen and (max-width: 470px) {
    font-size: 24px;
  }
`

export const Text = styled.p`
  color: #000000;
  font-size: 16px;
  line-height: 30px;
  text-align: center;


  @media screen and (max-width: 1024px) {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  @media screen and (max-width: 470px) {
    font-size: 15px;
  }
`

export const LogosWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    flex-direction: row;
    width: 100%;
  }
`

export const FirstLogoRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  width: 960px;
  margin-bottom: 50px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }
`

export const SecondLogoRow = styled.div`
  display: flex;
  justify-content: space-around;
  width: 960px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const FirstLogo = styled.img`
  width: 126px;
  height: 126px;

  @media screen and (max-width: 470px) {
    width: 90px;
    height: 90px;
  }
`

export const Logo = styled.img`
  height: auto;
  width: 20rem;
  @media screen and (max-width: 470px) {
    width: 80px;
    height: 80px;
  }
`

export default IndustryLeaders;