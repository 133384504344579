import styled from "styled-components";

const SectionWrapper = ({
  color,
  backgroundImage,
  backgroundRepeat,
  backgroundPosition,
  children,
  id,
  ...props
}) => {
  return (
    <Wraper
      color={color}
      backgroundImage={backgroundImage}
      backgroundRepeat={backgroundRepeat}
      backgroundPosition={backgroundPosition}
      id={id}
      {...props}
    >
      {children}
    </Wraper>
  );
};

const Wraper = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 8rem 18rem;
  width: 100%;
  align-items: center;

  background-color: ${(props) => props.color || "transparent"};
  background-image: ${(props) => props.backgroundImage || "none"};
  background-repeat: ${(props) => props.backgroundRepeat || "repeat"};
  background-position: ${(props) => props.backgroundPosition || "0% 0%"};

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    gap : 2rem;
    width: 100%;
    padding: 4rem 10rem;
    align-items: center;
    justify-content: center;

  }

  @media screen and (max-width: 768px) {
    padding: 3rem 4rem;
  }

  @media screen and (max-width: 470px) {
    padding: 2rem 2rem;
  }


`;

export default SectionWrapper;
