import React from "react";
import styled from "styled-components";
import {useState} from "react";
import {PersonCircle} from "@styled-icons/ionicons-solid/PersonCircle";
import {MenuOutline} from "@styled-icons/evaicons-outline/MenuOutline";
import {Delete} from "@styled-icons/typicons/Delete";
import {useNavigate} from "react-router-dom";
import Button from "../elements/Button";
import sendEmail from "../../utils/send-email";
<style>
  @import
  url('https://fonts.googleapis.com/css2?family=Anton&family=Bebas+Neue&family=Dancing+Script:wght@500;700&family=Montserrat:wght@200;300;400&family=Poppins&family=Questrial&family=Quicksand:wght@300&family=Raleway:wght@100&family=Roboto:wght@300&family=Space+Grotesk&family=Suez+One&display=swap');
</style>;

function Navbar() {
  const [showMenuSection, setShowMenuSection] = useState(false);
  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId)?.scrollIntoView({behavior: "smooth"});
  };

  return (
    <Wrapper>
      <>
        {showMenuSection ? (
          <MenuWrapper>
            <MenuItemsWrapper>
              <MenuItem onClick={() => scrollToSection("home")}>Home</MenuItem>
              <MenuItem onClick={() => scrollToSection("about")}>
                About
              </MenuItem>
              <MenuItem onClick={() => scrollToSection("services")}>
                Services
              </MenuItem>
              <MenuItem onClick={() => scrollToSection("projects")}>
                Projects
              </MenuItem>
              <MenuItem onClick={() => sendEmail()}>
                Contact
              </MenuItem>
            </MenuItemsWrapper>
          </MenuWrapper>
        ) : (
          <>
            <LogoWrapper>
              <Logo src="./img/logo.svg" />
            </LogoWrapper>

            <ItemsWrapper>
              <MenuItem onClick={() => scrollToSection("home")}>Home</MenuItem>
              <MenuItem onClick={() => scrollToSection("about")}>
                About
              </MenuItem>
              <MenuItem onClick={() => scrollToSection("services")}>
                Services
              </MenuItem>
              <MenuItem onClick={() => scrollToSection("projects")}>
                Projects
              </MenuItem>
              <MenuItem onClick={() => scrollToSection("contact")}>
                Contact
              </MenuItem>
            </ItemsWrapper>
              <ButtonWrapper>
                <Button onClick={() => sendEmail()}>Get In Touch</Button>
              </ButtonWrapper>
            <MenuIcon onClick={() => setShowMenuSection(true)} />
          </>
        )}
      </>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 4rem;

  @media screen and (max-width: 1024px) {
    align-items: center;
    width: 100%;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
`;

export const LogoTextWrapper = styled.div`
  margin-left: 15px;
`;

export const Logo = styled.img`
  width: 5rem;
`;

export const WeDu = styled.p`
  color: #000000;
  font-family: "Space Grotesk", sans-serif;
  font-size: 22px;
  margin-bottom: -13px;
  cursor: pointer;
`;

export const Desc = styled.p`
  color: #000000;
  font-size: 14px;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const FirstItem = styled.p`
  color: #6e6e6e;
  font-size: 16px;
  cursor: default;
`;

export const Item = styled.p`
  color: #000000;
  font-size: 16px;
  transition: 0.3s ease-in-out;
  cursor: default;

  :hover {
    color: #6e6e6e;
  }
`;

export const ProfileIcon = styled(PersonCircle)`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

export const ButtonWrapper = styled.div`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const MenuIcon = styled(MenuOutline)`
  display: none;

  @media screen and (max-width: 1024px) {
    display: initial;
    width: 50px;
    height: 50px;
  }

  @media screen and (max-width: 470px) {
    width: 40px;
    height: 40px;
  }
`;

export const MenuWrapper = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin-top: 50px;
  }
`;

export const MenuItemsWrapper = styled.div`
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    background-color: #ffffff;
    margin-left: 50px;
  }
`;

export const MenuItem = styled.p`
  color: #000000;
  font-size: 16px;
  transition: 0.3s ease-in-out;
  padding: 0.4rem 0.6rem;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: default;

  :hover {
    color: #6e6e6e;
    cursor: pointer;
    border: none;
    border-bottom: 2px solid var(--primary-color);
  }

  @media screen and (max-width: 1024px) {
    font-size: 22px;
    font-family: "Space Grotesk", sans-serif;
    font-weight: bold;
  }
`;

export const ExitIcon = styled(Delete)`
  width: 35px;
  height: 38px;
  cursor: pointer;
  margin-right: 50px;
`;

export default Navbar;
