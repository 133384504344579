import React from 'react';
import styled from "styled-components"
import SectionWrapper from '../ui/section-wrapper';
<style>
  @import url('https://fonts.googleapis.com/css2?family=Anton&family=Bebas+Neue&family=Dancing+Script:wght@500;700&family=Montserrat:wght@200;300;400&family=Poppins&family=Questrial&family=Quicksand:wght@300&family=Raleway:wght@100&family=Roboto:wght@300&family=Space+Grotesk&family=Suez+One&display=swap');
</style>

function ClientsReview() {
  return (
    <SectionWrapper
      style={{
        flexDirection: "column",
        alignItems: "center",
        justifContent: "center",
      }}
    >
      <TextWrapper>
        <Header>What Our Clients Say</Header>
      </TextWrapper>

      <CardsWrapper>
        <CardWrapper>
          <Text>
            “The innovative solutions provided have transformed our operations,
            making us more agile and efficient.”
          </Text>

          <ClientWrapper>
            <Plant src="./img/plant-1.svg" />
            <Client> Penyesa SA CEO.</Client>
          </ClientWrapper>
        </CardWrapper>

        <CardWrapper>
          <Text>
            “Their expertise in AI and development support has been instrumental
            in our growth. We now confidently tackle complex challenges.”
          </Text>

          <ClientWrapper>
            <Plant src="./img/plant-2.svg" />
            <Client>UJ PEETS</Client>
          </ClientWrapper>
        </CardWrapper>

        <CardWrapper>
          <Text>
            “Collaborating with them has been a game-changer. The team's
            dedication to our success is evident in every interaction.”
          </Text>

          <ClientWrapper>
            <Plant src="./img/plant-3.svg" />
            <Client>Overdub AI CTO</Client>
          </ClientWrapper>
        </CardWrapper>
      </CardsWrapper>
    </SectionWrapper>
  );
}


export const TextWrapper = styled.div`
  textAlign: center;

  @media screen and (max-width: 1024px) {
    margin-left: 0px;
  }
`

export const Header = styled.p`
  color: #000000;
  font-size: 38px;
  font-family: 'Space Grotesk', sans-serif;
  margin-bottom: 50px;

  @media screen and (max-width: 1024px) {
    text-align: center;
  }

  @media screen and (max-width: 470px) {
    font-size: 24px;
  }
`

export const CardsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  padding: 0 1rem;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("./img/board.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: calc(33.33% - 1rem); /* 3 cards per row with gap */
  max-width: 300px;
  min-width: 250px;
  flex-shrink: 0;
  flex-grow: 0;

  @media screen and (max-width: 1024px) {
    width: 80%;
    margin-bottom: 50px;
  }

  @media screen and (max-width: 470px) {
    width: 100%;
  }
`;

export const Text = styled.p`
  color: #000000;
  font-size: 16px;
  font-family: 'Space Grotesk', sans-serif;
  line-height: 1.6em;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
`

export const ClientWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
`

export const Plant = styled.img`
  width: 114px;
  height: 120px;
  margin-left: -20px;
`

export const Client = styled.p`
  color: #000000;
  font-size: 15px;
  font-weight: bold;
  width: 110px;
`

export default ClientsReview;