import React from "react";
import styled from "styled-components";

const Button = ({variant = "primary", children, ...props}) => {
  return variant === "primary" ? (
    <Primary {...props}>{children}</Primary>
  ) : (
    <Secondary {...props}>{children}</Secondary>
  );
};

const Base = styled.button`
  font-weight: bold;
  padding: 0.4rem 1.4rem;
  text-align: center;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: 0.3s ease-in-out;
  cursor: pointer; // Apply cursor directly to Base

  &:hover,
  &:focus {
    /* Add focus styles for accessibility */
    outline: none;
    box-shadow: 0 0 0 2px var(--primary-color); // Example focus style
  }
`;

const Primary = styled(Base)`
  background-color: var(--primary-color);
  color: #fff;
  border: 2px solid var(--primary-color);
`;

const Secondary = styled(Base)`
  background-color: var(--secondary-color);
  color: var(--button-text-color);
  border: 2px solid var(--secondary-color);
`;

export default Button;
